import useJwt from '@/auth/jwt/useJwt'
import { API_ROUTES } from '../config/routes/api'

export default {
	async getAll() {
		const params = {
			order: 'desc',
			order_by: 'name',
			offset: 0,
			limit: 0,
		}
		const { data } = await useJwt.get(API_ROUTES.exam.get, params)

		return data
	},

	async getAllBrandByClient(customer_id) {
		const url = API_ROUTES.brand.get.replace(':customer_id', customer_id)
		const { data } = await useJwt.get(url)

		return data
	},

	async getAllServicesByBrand(brand_id) {
		const url = API_ROUTES.service.get.replace(':brand_id', brand_id)

		const { data } = await useJwt.get(url)

		return data
	},

	async getAllAreaByService(service_id) {
		const params = {
			order: 'asc',
			order_by: 'name',
			offset: 0,
			limit: 200,
			'filters[0][field]': 'service_id',
			'filters[0][operator]': '=',
			'filters[0][value]': service_id,
		}
		const { data } = await useJwt.get(API_ROUTES.area.get, params)

		return data
	},

	async getAllSubareaByArea(area_id) {
		const url = API_ROUTES.subarea.get.replace(':area_id', area_id)
		const { data } = await useJwt.get(url)

		return data
	},

	async getAllLocationPatient(area_id) {
		const url = API_ROUTES.locationPatient.get.replace(':area_id', area_id)
		const { data } = await useJwt.get(url)

		return data
	},

	save(id, data) {
		return useJwt.put(`${API_ROUTES.locationPatient.save}/${id}`, data)
	},
}
